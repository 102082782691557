import React from 'react';
import type { Element } from 'react';
import { Error, RequestProvider } from '@devsta/common-react';
import {
  getNotificationsAndCountQuery,
  getUnreadCountQuery,
  getUserQuery,
  markAllAsReadMutation,
  setNotificationsReadMutation
} from '../api';
import RemoteNotificationsComponent from '../components/RemoteNotifications';

type RemoteNotificationsProps = {
  selectedOrganisation: Object,
  children: Element<any>
};

export default function RemoteNotifications(props: RemoteNotificationsProps) {
  const { children, selectedOrganisation: { organisationId } } = props;
  if (!organisationId) { return <Error />; }

  return (
    <RequestProvider
      requests={{
        getNotificationsAndCountRequest: getNotificationsAndCountQuery(),
        getUserRequest: getUserQuery(),
        getUnreadCountRequest: getUnreadCountQuery(),
        setNotificationsReadRequest: setNotificationsReadMutation(),
        markAllAsReadRequest: markAllAsReadMutation()
      }}
    >
      {requests => (
        <RemoteNotificationsComponent requests={requests}>
          {children}
        </RemoteNotificationsComponent>
      )}
    </RequestProvider>
  );
}
