// @flow
import _sortedUniqBy from 'lodash.sorteduniqby';
import _isEmpty from 'lodash.isempty';
import type { Notification } from '../types/notification';

export const sortLatestCreated = (a: Notification, b: Notification) => (
  b.createdAt - a.createdAt
);

export const filterNotEmpty = (obj: Object) => !_isEmpty(obj);

export const sortDedupNotifications = (notifications: Notification[]) => (
  _sortedUniqBy(notifications
    .filter(filterNotEmpty)
    .sort(sortLatestCreated), 'notificationId')
);
