import { authLocalStore } from '@devsta/client-auth';

export default async function login(
  loginReq: Function,
  payload: Object,
  { dataKey }: { dataKey: string} = { dataKey: 'login' }
) {
  try {
    const result = await loginReq(payload);
    const { data, error } = result;

    if (error || !data || !data[dataKey]) {
      return {
        error: 'Something went wrong. Try again',
        submitting: false
      };
    }

    const { [dataKey]: { auth } } = data;

    if (!auth) {
      return {
        error: 'The email or password you entered is incorrect',
        submitting: false
      };
    }

    authLocalStore.save(result);

    return null;
  } catch {
    return {
      error: 'Something went wrong. Try again',
      submitting: false
    };
  }
}
