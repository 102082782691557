import memoizeOne from 'memoize-one';
import moment from 'moment';
import getEndTime from './getEndTime';
import getTimeColor from './getTimeColor';

export default memoizeOne(({ dueDate, RUSH_EDIT }) => {
  const endTime = getEndTime(dueDate, RUSH_EDIT);

  const hoursDiff = moment(endTime).diff(moment(), 'hours');

  const hoursLeft = Math.max(hoursDiff, 0);

  return {
    color: getTimeColor(hoursLeft),
    hoursLeft
  };
});
