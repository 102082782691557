// @flow

import React, { Component } from 'react';
import { credentials } from '@devsta/client-auth';
import getOrgAlias from '@devsta/get-alias';
import { Error } from '@devsta/common-react';
import queryString from 'query-string';
import _get from 'lodash.get';
import type { Location, RouterHistory } from 'react-router-dom';
import type { Node } from 'react';
import { redirectToSingleSignOn, setSSOLocalStore } from '../../../../utils';

type SSOAuthProps = {
  getOrgSSOConfig: Function,
  location: Location,
  history: RouterHistory,
  children: Node
};

type SSOAuthState = {
  shouldRenderChildren: boolean,
  error?: String
}

export default class SSOAuth extends Component<SSOAuthProps, SSOAuthState> {
  constructor(props: SSOAuthProps) {
    super(props);

    this.state = { shouldRenderChildren: false };
  }

  async componentDidMount() {
    const { getOrgSSOConfig, location, history } = this.props;
    const orgAlias = getOrgAlias();
    const { data, error } = await getOrgSSOConfig(orgAlias);

    if (error) {
      return void this.setState({ error });
    }

    const ssoEnabled = _get(data, 'getOrganisationSSOConfig.ssoConfig.ssoEnabled');
    const existingCredentials = credentials.get();
    const { ghost, auth } = existingCredentials || {};

    // Handling for a SSO user already authenticated, hard refreshing the page
    if (ssoEnabled && (auth && ghost === void 0)) {
      setSSOLocalStore({ ssoEnabled: true });
      return void this.setState({ shouldRenderChildren: true });
    }

    // We set a ghost value for ghosting to skip sso
    // If user is already authenticated (auth = true) skip sso
    // Should probably come up with a better solution to this
    if (!ssoEnabled || ghost || auth) {
      return void this.setState({ shouldRenderChildren: true });
    }

    const urlQuery = queryString.parse(location.search);

    // If sso query param is not present means a SSO user
    // is directly trying to use HUB login rather than his SSO login
    if (!urlQuery.sso) {
      return void redirectToSingleSignOn(location.pathname);
    }

    const decodedData = atob(String(urlQuery.sso));

    credentials.set(JSON.parse(decodedData));
    setSSOLocalStore({ ssoEnabled: true });

    history.push(location.pathname);

    this.setState({ shouldRenderChildren: true });
  }

  render() {
    const { shouldRenderChildren, error } = this.state;
    const { children } = this.props;

    if (error) { return <Error />; }

    if (!shouldRenderChildren) { return null; }

    return children;
  }
}
