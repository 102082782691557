export const COLORS = {
  orange: '#ff9f19',
  monkey: '#27bba1',
  redApple: '#be1d22'
};

export const STATUS_COLORS_MAP = {
  APPROVED: COLORS.monkey,
  PENDING_MASTER: COLORS.orange,
  EDITING: COLORS.monkey,
  INTERNAL_REVIEW: COLORS.orange,
  PENDING_REVIEW: COLORS.orange,
  DRAFT: COLORS.redApple
};
