import { gql } from 'apollo-boost';

export const loginAnonymousUser = gql`
  mutation ($firstName: String!, $lastName: String!) {
      loginAnonymousUser(firstName: $firstName, lastName: $lastName) {
            auth
            token
            user {
                userId
                firstName
                lastName
                isAnonymous
            }
        }
    }
`;

export default () => ({
  mutation: loginAnonymousUser,
  module: 'users',
  buildVariables: (
    { firstName, lastName }: { firstName: string, lastName: string }
  ) => ({ firstName, lastName })
});
