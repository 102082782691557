import { COLORS } from '../../../constants/colors';

export default (hoursLeft: number) => {
  if (hoursLeft > 4) {
    return COLORS.monkey;
  }

  if (hoursLeft > 2) {
    return COLORS.orange;
  }

  return COLORS.redApple;
};
