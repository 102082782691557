// @flow

import React from 'react';
import { RequestProvider } from '@devsta/common-react';
import WelcomeModalComponent from '../components/WelcomeGuestModal';
import { loginApi } from '../../../api';
import { loginAnonymousUserApi } from '../api';


export default function WelcomeGuest(props: Object) {
  return (
    <RequestProvider
      requests={{
        loginRequest: loginApi(),
        loginAnonymousUserRequest: loginAnonymousUserApi()
      }}
    >
      {({ loginRequest, loginAnonymousUserRequest }) => (
        <WelcomeModalComponent
          loginRequest={loginRequest}
          loginAnonymousUserRequest={loginAnonymousUserRequest}
          {...props}
        />
      )}
    </RequestProvider>
  );
}
