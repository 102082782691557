// @flow

import React from 'react';
import { AuthorisationConsumer } from '@devsta/common-react';
import SwitchOrganisationModalComponent from '../components/SwitchOrganisationModal';

function organisationsToSwitch(organisationMemberships, selectedOrganisation) {
  const filteredOrganisationMemberships = organisationMemberships
    .filter((
      { organisation }
    ) => organisation.organisationId !== selectedOrganisation.organisationId);

  return filteredOrganisationMemberships.map(({ organisation }) => organisation);
}

function SwitchOrganisationModal(props: Object) {
  return (
    <AuthorisationConsumer>
      {
        ({ updateSelectedOrganisation, organisationMemberships, selectedOrganisation }) => (
          <SwitchOrganisationModalComponent
            updateSelectedOrganisation={updateSelectedOrganisation}
            organisations={organisationsToSwitch(organisationMemberships, selectedOrganisation)}
            selectedOrganisation={selectedOrganisation}
            {...props}
          />
        )
      }
    </AuthorisationConsumer>
  );
}

export default SwitchOrganisationModal;
