// @flow
import React from 'react';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import { Error } from '@devsta/common-react';
import { authLocalStore } from '@devsta/client-auth';
import type { Node } from 'react';

type GhostParams = {
  token: string,
  user: string,
  original?: string,
  origURL?: string,
  targetURL: string,
  clearGhost?: boolean
};

export default function Ghost({ children }: { children: Node }) {
  const urlParams = queryString.parse(window.location.search);

  // url params value can be string, array or null
  if (!urlParams.ghost || Array.isArray(urlParams.ghost)) {
    return children;
  }

  try {
    const {
      token,
      user,
      original,
      origURL,
      targetURL,
      clearGhost
    }: GhostParams = JSON.parse(atob(urlParams.ghost));

    const decodedToken = jwt.decode(token);

    if (!decodedToken) { return <Error />; }

    const data = {
      auth: true,
      ghost: !clearGhost,
      token,
      user,
      original,
      origURL
    };

    authLocalStore.save(data);

    window.location.replace(targetURL);

    return null;
  } catch (e) {
    return <Error />;
  }
}
