import getOrgAlias from '@devsta/get-alias';
import config from '../config';

export function redirectToSingleSignOn(path: string) {
  window.location.href = `${String(config.ssoApiEndpoint)}?al=${getOrgAlias()}&url=${path}`;
}

export function redirectToSingleLogout() {
  window.location.href = `${String(config.ssoApiEndpoint)}logout?al=${getOrgAlias()}`;
}
