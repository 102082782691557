// 24 hours
const NO_RUSH_TIME = 24;

const RUSH_TIME_MAP = {
  FAST: NO_RUSH_TIME / 2,
  SUPER_FAST: NO_RUSH_TIME / 4
};

export default function (RUSH_EDIT?: { value: string }) {
  return RUSH_EDIT && RUSH_EDIT.value ? RUSH_TIME_MAP[RUSH_EDIT.value] : NO_RUSH_TIME;
}
