import moment from 'moment';
import getRushValue from './getRushValue';

const getDate = (date: string) => {
  const dateNumber = Number(date);

  const isTimestamp = !Number.isNaN(dateNumber);

  return isTimestamp ? moment(dateNumber) : date;
};

export default function (dueDate: string, RUSH_EDIT?: { value: string }) {
  const rushHours = getRushValue(RUSH_EDIT);

  return moment(getDate(dueDate)).add(rushHours, 'hours').format();
}
